import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const DogFoodCostBreakdown = () => (
  <StaticQuery
    query={graphql`
      query DogFoodCostsQuery {
        allDogFoodExpensesCsv {
          nodes {
            field1 # Date
            field2 # Title
            field3 # Category
            field4 # Item Total
          }
        }
      }

    `}
    render={data => {
      const items = data.allDogFoodExpensesCsv.nodes.slice();

      

      return (
        <div style={{ margin: '-20px', height: '500px' }}>
          {JSON.stringify(items)}
        </div>
      );
    }}
  />
);

export default DogFoodCostBreakdown;
